body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar {
  width: 4px;
  /* height: 8px; */
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #afafaf;
}
.Mui-disabled {
  opacity: 0.6;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 8px;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.MuiListItem-button {
  height: 57px;
}

.MuiListItem-root {
  background-color: transparent !important;
}
.MuiListItem-root.Mui-selected * {
  color: #ff5078 !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
}

/* .MuiListItemText-root span {
  color: #3e3e46;
} */
.MuiDivider-root {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.makeStyles-toolbar-10 {
  justify-content: flex-start !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #ff5078 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff5078 !important;
}
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 65px;
}
/* .MuiCardContent-root:last-child {
  padding-bottom: 8px !important;
} */
/* .MuiCardContent-root {
  padding: 5px 10px 5px 20px !important;
} */
.MuiFormHelperText-root.Mui-error {
  color: #f44336;
  position: absolute;
  bottom: -24px;
  width: 290px;
  left: -15px;
  font-size: 11px;
}
.MuiPickersToolbar-toolbar {
  background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  );
}
.MuiPickersDay-daySelected {
  background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  );
}
.MuiPickerDTTabs-tabs {
  background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  );
}
.MuiButton-textPrimary {
  color: #ff5078 !important;
}
.MuiFormControl-root {
  min-width: 200px !important;
}
.MuiTableCell-root {
  font-size: 12px !important;
  white-space: nowrap;
}

.MuiTableCell-head {
  font-size: 14px !important;
  color: #aeaeae !important;
  font-weight: 500 !important;
}

.MuiTableCell-body {
  font-size: 12px !important;
  font-weight: 500;
  color: #404040 !important;
}

.MuiTypography-body1 {
  font-size: 14px;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
  letter-spacing: 0.00938em;
  width: max-content;
}

.squareCode {
  width: 106px !important;
  height: initial !important;
}
.rectangleCode {
}
@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.animatedBorder {
  padding: 8px 8px 8px 8px;
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-size: 300% 300%;
  background-position: 0 50%;
  animation: moveGradient 4s alternate infinite;
  border-radius: 12px;
}
